import { ICompanyData } from "../Interface/ICompanyData.interface";

export const CompanyData: ICompanyData[] = [
    {
        title: "aboutColTitle1",
        description: [ "aboutColText1-1", "aboutColText1-2" ]
    },
    {
        title: "aboutColTitle2",
        description: "aboutColText2"
    },
    {
        title: "aboutColTitle3",
        description: "aboutColText3"
    },
    {
        title: "aboutColTitle4",
        description: "aboutColText4"
    },
    {
        title: "aboutColTitle5",
        description: "aboutColText5"
    },
    {
        title: "aboutColTitle6",
        description: "aboutColText6"
    },
    {
        title: "aboutColTitle7",
        description: "aboutColText7"
    },
    {
        title: "aboutColTitle8",
        description: "aboutColText8"
    },
    {
        title: "aboutColTitle9",
        description: "aboutColText9"
    },
    {
        title: "aboutColTitle10",
        description: "aboutColText10"
    },
    {
        title: "aboutColTitle11",
        description: [
            "aboutColText11-1",
            "aboutColText11-2",
            "aboutColText11-3",
            "aboutColText11-4"
        ]
    }
];
