import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IHistory } from "xa-generics";
import "./Less/E404.css";

export interface IE404Props {}

const PageNotFound: React.FC<IE404Props> = (props): React.ReactElement => {
    const { t } = useTranslation();
    const history: IHistory<void> = useHistory();
    return (
        <div className="e404-wrapper">
            <div className="e404">
                <div className="e404__title">{t("err404Title")}</div>
                <div className="e404__text">{t("err404Text")}</div>
                <button onClick={() => history.push("/")} className="e404__button">
                    {t("err404GoBack")}
                </button>
            </div>
        </div>
    );
};

export default PageNotFound;
