import React from "react";
import { Management } from "../../MainPage/Static/Managers.static";
import { IPersonnel } from "../Interface/IPersonnel.interface";
import { CompanyData } from "../Static/CompanyData.static";
import { ICompanyData } from "../Interface/ICompanyData.interface";
import { useTranslation } from "react-i18next";
import "./Less/AboutUs.css";

export interface ICompanyDataProps {}

const CompanyDataView: React.FC<ICompanyDataProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="about-section">
            <h2 className="about-section__title">{t("aboutUsTitle")}</h2>
            <div className="about-section__table">
                {CompanyData.map((item: ICompanyData, index: number) => (
                    <div className="about-section__table--row" key={`${index}-company-section-row`}>
                        <div className="about-section__table--col title-column">
                            <span>{t(item.title)}</span>
                        </div>
                        <div className="about-section__table--col description-column">
                            {item.description instanceof Array ? (
                                item.description.map((desc: string, descIndex: number) => (
                                    <span className="list-desc" key={`${descIndex}-${index}-desc`}>
                                        {t(desc)}
                                    </span>
                                ))
                            ) : (
                                <span>{t(item.description)}</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <h2 className="about-section__title">{t("leadersTitle")}</h2>
            <div className="about-section__table">
                {Management.map((item: IPersonnel, index: number) => (
                    <div className="about-section__table--row" key={`${index}-management-row`}>
                        <div className="about-section__table--col title-column">
                            <span>{item.title}</span>
                        </div>
                        <div className="about-section__table--col description-column">
                            <span>{t(item.description)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CompanyDataView;
