import { IImpressData } from "../Interface/IImpressData.interface";

export const ImpressData: IImpressData[] = [
    {
        title: "impressColTitle1",
        description: ["impressColText1-1", "impressColText1-2"]
    },
    {
        title: "impressColTitle2",
        description: "impressColText2"
    },
    {
        title: "impressColTitle3",
        description: "impressColText3"
    },
    {
        title: "impressColTitle4",
        description: "impressColText4"
    },
    {
        title: "impressColTitle5",
        description: "impressColText5"
    },
    {
        title: "impressColTitle6",
        description: "impressColText6"
    },
    {
        title: "impressColTitle7",
        description: "impressColText7"
    },
    {
        title: "impressColTitle8",
        description: "impressColText8"
    },
    {
        title: "impressColTitle9",
        description: "impressColText9"
    },
    {
        title: "impressColTitle10",
        description: "impressColText10"
    },
    {
        title: "impressColTitle11",
        description: "impressColText11-1"
    },
    {
        title: "impressColTitle12",
        description: "impressColText12"
    }
];
