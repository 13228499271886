import * as React from "react";
import { useTranslation } from "react-i18next";
import HeaderIllustration from "../../../Assets/mainPageIllustartion.png";
// import ManagementView from "./Management.view";
// import ActiveProjects from "./ActiveProjects.view";
import Spinner from "../../Spinner/Spinner";
import Img from "react-image";
import "./Less/MainPage.view.css";

export interface mainPageProps {}

const MainPage: React.SFC<mainPageProps> = () => {
    const { t } = useTranslation();

    return (
        <div className="main-page-component">
            <div className="main-page-header-section">
                <div className="main-page-header-section__container">
                    <div className="main-page-header-section__container--left">
                        <h1 className="main-page-header__title">{t("mainPageTitle")}</h1>
                        <p className="main-page-header__introduction">
                            <span>{t("mainPageIntroduction1")}</span>
                            <span>{t("mainPageIntroduction2")}</span>
                            <span>{t("mainPageIntroduction3")}</span>
                            <span>{t("mainPageIntroduction4")}</span>
                            <span>{t("mainPageIntroduction5")}</span>
                            <span>
                                {t("xangaLinkText1")}{" "}
                                <a
                                    target="_blank"
                                    href="https://www.xanga.hu"
                                    rel="noreferrer noopener"
                                >
                                    https://www.xanga.hu
                                </a>{" "}
                                {t("xangaLinkText2")}
                            </span>
                            <span>
                                {t("xangaProjectLinkText")}{" "}
                                <a
                                    target="_blank"
                                    href="https://www.xanga.hu/projects"
                                    rel="noreferrer noopener"
                                >
                                    https://www.xanga.hu/projects
                                </a>
                            </span>
                        </p>
                    </div>
                    <div className="main-page-header-section__container--right">
                        <Img src={HeaderIllustration} alt="Illustration" loader={<Spinner />} />
                    </div>
                </div>
            </div>
            {/* <ManagementView /> */}
            {/* <ActiveProjects /> */}
        </div>
    );
};

export default MainPage;
