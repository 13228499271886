import React from "react";
import { ImpressData } from "../Static/ImpressData.static";
import { IImpressData } from "../Interface/IImpressData.interface";
import { useTranslation } from "react-i18next";
import "./Less/AboutUs.css";

export interface IImpressDataProps {}

const ImpressDataView: React.FC<IImpressDataProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="about-section">
            <h2 className="about-section__title">{t("impressUsTitle")}</h2>
            <h3 className="about-section__subtitle">{t("impressSubTitle")}</h3>
            <div className="about-section__table">
                {ImpressData.map((item: IImpressData, index: number) => (
                    <div className="about-section__table--row" key={`${index}-company-section-row`}>
                        <div className="about-section__table--col title-column">
                            <span>{t(item.title)}</span>
                        </div>
                        <div className="about-section__table--col description-column">
                            {item.description instanceof Array ? (
                                item.description.map((desc: string, descIndex: number) => (
                                    <span className="list-desc" key={`${descIndex}-${index}-desc`}>
                                        {t(desc)}
                                    </span>
                                ))
                            ) : (
                                <span>{t(item.description)}</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImpressDataView;
