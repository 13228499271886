import { initReactI18next } from "react-i18next";
import I18Next from "i18next";
import EN from "./EN.json";
import HU from "./HU.json";

export const InitLanguages = (): void => {
    I18Next.use(initReactI18next).init({
        resources: {
            en: {
                translation: EN
            },
            hu: {
                translation: HU
            }
        },
        lng: "hu",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });
};
