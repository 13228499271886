import React from "react";
import { FC } from "react";
import "./Less/Szechenyi.css";

interface ISzechenyi2020Props {}

export const Szechenyi2020: FC<ISzechenyi2020Props> = (props) => {
    return (
        <div className={"szechenyi"}>
            <div className="szechenyi__page">
                <div className="row">
                    <h6 className="szechenyi__label">Kezdeményezett neve:</h6> XANGA VENTURES
                    MAGÁNTŐKEALAP-KEZELŐ ZRT.
                </div>
                <div className="row">
                    <h6 className="szechenyi__label">Projekt címe:</h6> ESG PILOT – TANÁCSADÁSI
                    PROGRAM A XANGA VENTURES MAGÁNTŐKEALAP-KEZELŐ ZRT.-NÉL
                </div>
                <div className="row">
                    <h6 className="szechenyi__label">Támogatás összege:</h6> 13,5 MILLIÓ FORINT
                </div>
                <div className="row">
                    <h6 className="szechenyi__label">Támogatás Mértéke:</h6> 100 %
                </div>
                <h6 className="szechenyi__label --BREAK">Projekt tartalmának bemutatása:</h6> A
                XANGA Ventures Magántőkealap-kezelő Zrt. 2019-ben kapott MNB engedélyt az
                alapkezelői tevékenység végzéséhez. Az induló Debreceni Iparfejlesztési Alap mellett
                további négy magántőkealap kezelését kezdte meg 2022.12.31-ig. A Társaság eddigi
                működése a folyamatos fejlődést tükrözi, amit a jövőben még magasabb szintre
                kívánunk emelni. Társaságunk elkötelezett társadalmi szerepvállalásának fokozásában
                és a folyamatosan változó környezeti hatásokhoz való alkalmazkodási képesség,
                reziliencia megerősítésében. Működésünk transzparenssé tételéhez szükségesnek látjuk
                az ESG szemléletmód szervezeti kultúrába integrálását. A projekt célja, hogy ESG
                törekvéseinket stratégiai szinten tudatosan tudjuk kezelni, megfelelő zöld
                finanszírozással kiegészítve. Hisszük, hogy az ESG jelentés elkészítése és
                közzététele pozitívan befolyásolja Társaságunk piaci és társadalmi megítélését, és
                emellett mérhető gazdasági előnyöket is jelent. ESG stratégiánk és jelentésünk
                elkészítéséhez előzetesen szükségesnek látjuk a jelenlegi vállalati működésünk
                átvilágítását, illetve a lényeges fenntarthatósági témák meghatározását.
                <br />
                <span className="szechenyi__weak-label">
                    Az ESG-tudatos működés számos közvetlen előnnyel jár számunkra:
                </span>
                <ul>
                    <li className={"li"}>
                        Mivel a bankoknak is érdeke az ESG kompatibilis finanszírozási portfolió
                        bővítése, bízunk abban, hogy megfelelő transzparencia mellett a rendkívül
                        költséges ingatlanfejlesztési projektekhez kedvezőbb feltételekkel tud majd
                        vállalatunk banki forrásokhoz jutni. Ennek köszönhetően az ajánlatkéréskor a
                        felkínált projektjeink esetén fokozottabb versenyhelyzetet tudunk teremteni.
                    </li>
                    <li className={"li"}>
                        Az ESG szemléletmód pozitív gazdasági hozadékkal járhat a megvalósított
                        projektek exitjénél is. Az ESG szempontok alapján megtervezett projektek
                        rövid- és középtávon versenyelőnyt élvezhetnek, így az exit gyorsabb és
                        jövedelmezőbb lehet, mint a nem ESG kompatibilis eszközök esetén.
                    </li>
                    <li className={"li"}>
                        Az ESG tudatos működésünk segíti a bérlőink elvárásainak való megfelelést
                        is. A Debrecen környéki gyárak (pl.: BMW), valamit egyéb ipari szereplők
                        betelepülése olyan ingatlanok fejlesztését indokolja, melyeknél előnyt
                        jelent az ESG szempontrendszer érvényesülése.
                    </li>
                </ul>
                Szervezetfejlesztési törekvéseink hosszú távú célja, hogy vállalatunk kilépjen a „de
                minimis” ABAK követelményrendszerből, amely szigorúbb felügyeleti elvárások
                teljesítését követeli meg. Ez a lépés lehetővé tenné, hogy olyan alapok kezelését is
                végezhessük, amelyek a tőkepiacokról (akár a tőzsdén keresztül) tudnak forrást
                bevonni. Ehhez az ESG jelentések készítése és közzététele elengedhetetlen lesz. A
                programba történő jelentkezésünk az erre való felkészülést szolgálja.
                <div className="row">
                    <h6 className="szechenyi__label">Befejezési dátuma:</h6> 2023. május 31.
                </div>
                <div className="row">
                    <h6 className="szechenyi__label">Projekt azonosító száma:</h6>{" "}
                    GINOP-1.1.7-17-BÉT-6-008
                </div>
            </div>
        </div>
    );
};
