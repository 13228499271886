import { IFoundation } from "../Interface/IFoundation.interface";

export const FoundationCards: IFoundation[] = [
    {
        text: "found-el1-title",
        description: "found-el1-description",
        link: "/kezelt-alapok/1",
        footerText: "found-el1-footer-text"
    },
    {
        text: "found-el2-title",
        description: "found-el2-description",
        link: "/kezelt-alapok/2",
        footerText: "found-el2-footer-text"
    },
    {
        text: "found-el3-title",
        description: "found-el3-description",
        link: "/kezelt-alapok/3",
        footerText: "found-el3-footer-text"
    },
    {
        text: "found-el4-title",
        description: "found-el4-description",
        link: "/kezelt-alapok/4",
        footerText: "found-el4-footer-text"
    }
];
