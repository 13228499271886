import { IPersonnel } from "../../CompanyDetails/Interface/IPersonnel.interface";

export const Management: IPersonnel[] = [
    {
        title: "Herdon István",
        description: "leadersDesc1"
    },
    {
        title: "Csukás Endre",
        description: "leadersDesc2"
    },
    {
        title: "Herdon László",
        description: "leadersDesc5"
    },
    {
        title: "Kovács Zsolt",
        description: "leadersDesc6"
    },
    {
        title: "Nagy Nándor",
        description: "leadersDesc7"
    }
];
