import React from "react";
import ReactDOM from "react-dom";
import Application from "./Components/App/App.routes";
import { BrowserRouter } from "react-router-dom";
import { InitLanguages } from "./Lang/I18Next";
import { NavigationFactory } from "xa-navigation";
import * as serviceWorker from "./serviceWorker";
import * as routers from "./Components/Navigation/Static/NavigationRoutes.static";
import "./Css/Index.css";

InitLanguages();
NavigationFactory.initialize(routers);

const App: JSX.Element = (
    <BrowserRouter>
        <Application />
    </BrowserRouter>
);

ReactDOM.render(App, document.getElementById("root"));
serviceWorker.unregister();
