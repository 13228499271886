import React, { useContext, useState } from "react";
import { sortBy } from "lodash";
import { IHistory } from "xa-generics";
import { useHistory } from "react-router";
import { AccessModel, AccessContext } from "access-factory";
import { INavElement, NavigationFactory } from "xa-navigation";
import Logo from "../../../Assets/xanga_ventures_logo.png";
import "./Less/Toolbar.css";

export interface IToolbarViewProps {}

const ToolbarView: React.FC<IToolbarViewProps> = (props): React.ReactElement => {
    const [opened, set_opened] = useState<boolean>(false);
    const access: AccessModel = useContext(AccessContext).accessLevel;
    const history: IHistory<void> = useHistory();

    const routes: INavElement[] = sortBy(NavigationFactory.getMyRoutes(access), "sort_order");

    const classes: string[] = ["navbar"];
    if (opened) classes.push("navbar-open");

    const handleNavElementClick = (route: string): void => {
        history.push(route);
        if (opened) set_opened(false);
    };

    return (
        <div className={classes.join(" ")}>
            <div className="navbar__group-name">XANGAVENTURES</div>
            <div className="navbar__wrapper">
                <div className="navbar__brand" onClick={() => handleNavElementClick("/")}>
                    <img
                        src={Logo}
                        alt="XANGA"
                        className="navbar__brand--logo"
                        onClick={() => history.push("/")}
                    />
                </div>
                <div className="navbar__content">
                    {routes.map((nav: INavElement, index: number) => {
                        if (nav.text.toLocaleLowerCase() !== "impresszum") {
                            return (
                                <div
                                    className="navbar__content--route"
                                    key={`${index}-nav-route-${nav.route}`}
                                    onClick={() => handleNavElementClick(nav.route)}
                                >
                                    {nav.text}
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
            <div className="backdrop" onClick={() => set_opened(false)} />
            <div className="threads" onClick={() => set_opened(!opened)}>
                <div className="thread" />
                <div className="thread" />
                <div className="thread" />
            </div>
        </div>
    );
};

export default ToolbarView;
