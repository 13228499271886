import React from "react";
import NavbarController from "../../Navigation/Controller/Navbar.controller";
import Logo from "../../../Assets/xanga_ventures_logo.png";
import "./Less/Layout.css";
import { useHistory } from "react-router-dom";

export interface ILayoutProps {}

const Layout: React.FC<ILayoutProps> = (props): React.ReactElement => {
    const history = useHistory();
    return (
        <React.Fragment>
            <NavbarController />
            <div className="header-image">{props.children}</div>
            <div className="footer">
                <div onClick={() => history.push("/impresszum")} className="impress">
                    Impresszum
                </div>
                <a
                    className="impress"
                    href="/Jogi_nyilatkozat.docx"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Jogi Nyilatkozat
                </a>
                <div className="top">XANGA Ventures {new Date().getFullYear()}</div>
                <div className="bottom">
                    <img src={Logo} alt="XANGA Ventures" className="logo-img" />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Layout;
