import React from "react";
import { useTranslation } from "react-i18next";
import "./Less/Complaints.css";

export interface IComplaintsProps {}

const Complaints: React.FC<IComplaintsProps> = (props): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="complaints-wrapper">
            <div className="complaints">
                <div className="complaints__title">{t("complaintsTitle")}</div>
                <div className="complaints__title--small">{t("complaintsText1")}</div>
                <div className="complaints__text">{t("complaintsText2")}</div>
                <div className="complaints__strong">{t("complaintsText3")}</div>
                <ol type="a">
                    <li>{t("complaintsText4")}</li>
                    <li>{t("complaintsText5")}</li>
                    <li>{t("complaintsText6")}</li>
                    <li>{t("complaintsText7")}</li>
                    <li>{t("complaintsText8")}</li>
                    <li>{t("complaintsText9")}</li>
                    <li>{t("complaintsText10")}</li>
                </ol>
                <u>
                    <div className="complaints__strong">{t("complaintsText11")}:</div>
                </u>
                <div className="complaints__text">{t("complaintsText12")}</div>

                <div className="complaints__strong">{t("complaintsText13")}</div>
                <ul>
                    <li>{t("complaintsText14")}</li>
                    <li>{t("complaintsText15")}</li>
                </ul>
                <div className="complaints__strong">{t("complaintsText16")}</div>
                <ul>
                    <li>{t("complaintsText17")}</li>
                    <li>{t("complaintsText18")}</li>
                </ul>
                <div className="complaints__text">{t("complaintsText19")}</div>
                <div className="complaints__text">{t("complaintsText20")}</div>
                <div className="complaints__text">{t("complaintsText21")}</div>
                <div className="complaints__text">{t("complaintsText22")}</div>
                <div className="complaints__text">{t("complaintsText23")}</div>
                <div className="complaints__files">
                    <div className="complaints__files--container">
                        <span>
                            Ügyféltájékoztató az Alapkezelő által alkalmazott panaszkezelési
                            szabályokról
                        </span>
                        <a
                            href="/Panaszkezelesi_Szabalyzat.pdf"
                            target="_blank"
                            className="a-button"
                            rel="noreferrer noopener"
                            download={true}
                        >
                            Letöltés
                        </a>
                    </div>
                    <div className="complaints__files--container">
                        <span>Panasz nyomtatvány</span>
                        <a
                            href="/panasznyomtatvany.pdf"
                            className="a-button"
                            target="_blank"
                            rel="noreferrer noopener"
                            download={true}
                        >
                            Letöltés
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Complaints;
