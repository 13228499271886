import React from "react";
import { useTranslation } from "react-i18next";
import "./Css/DataHandling.css";

export interface IDataHandlingProps {}

const DataHandling: React.FC<IDataHandlingProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="publishing-view">
            <div className="publishing-container">
                <div className="publishing-data-row">
                    <div className="name">Adatfeldolgozók 2023</div>
                    <a
                        href="/Adatfeldolgozok_2023.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Alapkezelés</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Alapkezeles.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Érintetti joggyakorlás</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Erintetti_joggyakorlas.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Kamera Step2</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Kamera_Step2.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Panaszkezelés</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Panaszkezeles.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Számlázás</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Szamlazas.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Üzenetváltás</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Uzenetvaltas.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatkezelési Tájékoztató Videókonferencia</div>
                    <a
                        href="/Adatkezelesi_Tajekoztato_Videokonferencia.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Adatvédelmi Politika</div>
                    <a
                        href="/Adatvedelmi_Politika.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
                <div className="publishing-data-row">
                    <div className="name">Fogalommeghatározások</div>
                    <a
                        href="/Fogalommeghatarozasok.pdf"
                        target="_blank"
                        className="a-button"
                        rel="noreferrer noopener"
                    >
                        {t("view")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DataHandling;
