import React from "react";
import ToolbarView from "../View/Toolbar.view";

export interface INavbarControllerProps {}

const NavbarController: React.FC<INavbarControllerProps> = (props): React.ReactElement => {
    return <ToolbarView />;
};

export default NavbarController;
