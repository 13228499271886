import React from "react";
import { useTranslation } from "react-i18next";
import "./Less/Connection.css";

export interface IConnectionProps {}

const Connection: React.FC<void> = (): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="connections-wrapper">
            <div className="connection">
                <div className="connection__title">{t("connectionTitle")}</div>
                <div className="connection__table">
                    <div className="connection__table--row">
                        <div className="connection__table--col title-col">
                            <span>{t("connectionAddress")}</span>
                        </div>
                        <div className="connection__table--col desc-col">
                            <span>4025 Debrecen, Simonffy u. 4-6 I/121</span>
                        </div>
                    </div>
                    <div className="connection__table--row">
                        <div className="connection__table--col title-col">
                            <span>{t("connectionEmail")}</span>
                        </div>
                        <div className="connection__table--col desc-col">
                            <span>info@xangaventures.com</span>
                        </div>
                    </div>
                    <div className="connection__table--row">
                        <div className="connection__table--col title-col">
                            <span>{t("connectionWeb")}</span>
                        </div>
                        <div className="connection__table--col desc-col">
                            <span>www.xangaventures.com</span>
                        </div>
                    </div>
                    <div className="connection__table--row">
                        <div className="connection__table--col title-col">
                            <span>{t("connectionPhone")}</span>
                        </div>
                        <div className="connection__table--col desc-col">
                            <span>+36 52 534 950</span>
                        </div>
                    </div>
                    <div className="connection__table--row">
                        <div className="connection__table--col title-col">
                            <span>{t("connectionFax")}</span>
                        </div>
                        <div className="connection__table--col desc-col">
                            <span>+36 52 534 951</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Connection;
