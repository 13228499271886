import React from "react";
import { FoundationCards } from "../Static/Foundations.static";
import { useTranslation } from "react-i18next";
import { IFoundation } from "../Interface/IFoundation.interface";
import { useHistory } from "react-router-dom";
import "./Less/Foundations.css";

export interface IFoundationsProps {}

const Foundations: React.FC<IFoundationsProps> = (): React.ReactElement => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div className="foundations-wrapper">
            <div className="foundations">
                <div className="foundations__title">{t("foundationsTitle")}</div>
                <div className="foundations__sub-title">{t("foundationsSubTitle")}</div>
                <div className="foundations__card-container">
                    {FoundationCards.map((card: IFoundation, index: number) => (
                        <div className="card" key={`${index}-foundation-card`}>
                            <div
                                className="foundation-card"
                                onClick={() => history.push(card.link)}
                            >
                                <div className="foundation-card__title">{t(card.text)}</div>
                                <div className="foundation-card__description">
                                    {t(card.description)}
                                </div>
                                <div className="foundation-card__investment">
                                    {card.footerText ? (
                                        <span className="foundation-card__footer-text">
                                            {t(card.footerText)}
                                        </span>
                                    ) : null}
                                    <div className="arrow-container">
                                        <div className="arrow-body" />
                                        <div className="arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Foundations;
