import React from "react";
import { IWithRouter, NavigationRouter } from "xa-navigation";
import { withRouter, Switch } from "react-router";
import { ApplicationAccess } from "access-factory";
import PageNotFound from "../E404/View/E404.view";
import CompanyDataView from "../CompanyDetails/View/CompanyData.view";
import FoundationView from "../Foundations/View/Foundation.view";
import Foundations from "../Foundations/View/Foundations.view";
import Connection from "../Connection/View/Connection.view";
import Complaints from "../Complaints/View/Complaints.view";
import Publishing from "../Publishing/View/Publishing.view";
import MainPage from "../MainPage/View/MainPage.view";
import Layout from "../Layout/View/Layout.view";
import ImpressDataView from "../Impress/View/ImpressData.view";
import DataHandling from "../DataHandling/View/DataHandling.view";
import EUImg from "../../Assets/szechenyi_2020_befektetes_a_jovobe_kedvezmenyezett.png";
import { NavLink } from "react-router-dom";
import { Szechenyi2020 } from "../Szechenyi2020/View/Szechenyi2020.view";
// import Posts from "../Posts/View/Posts.view";

const AppRoutes: React.FC<IWithRouter> = (props): React.ReactElement => {
    const routes: React.ReactNode = (
        <Switch>
            <NavigationRouter path="/cegadatok" component={CompanyDataView} exact={true} />
            <NavigationRouter path="/kezelt-alapok" component={Foundations} exact={true} />
            <NavigationRouter
                path="/kezelt-alapok/:foundation_id"
                component={FoundationView}
                exact={true}
            />
            {/* <NavigationRouter path="/posztok" component={Posts} exact={true} /> */}
            <NavigationRouter path="/kozzetetel" component={Publishing} exact={true} />
            <NavigationRouter path="/kapcsolat" component={Connection} exact={true} />
            <NavigationRouter path="/panaszkezeles" component={Complaints} exact={true} />
            <NavigationRouter path="/impresszum" component={ImpressDataView} exact={true} />
            <NavigationRouter path="/adatkezeles" component={DataHandling} exact={true} />
            <NavigationRouter
                path="/szechenyi-2020-befektetes-a-jovobe"
                component={Szechenyi2020}
                exact={true}
            />
            <NavigationRouter path="/" component={MainPage} exact={true} />
            <NavigationRouter path="*" component={PageNotFound} exact={true} />
        </Switch>
    );
    return (
        <>
            <ApplicationAccess prevent_autologin={true} Spinner={null}>
                <Layout>{routes}</Layout>
            </ApplicationAccess>
            <NavLink to="/szechenyi-2020-befektetes-a-jovobe">
                <img
                    src={EUImg}
                    className={"global-eu-img"}
                    alt={"Széchenyi 2020 befektetés a jövőbe kedvezményezett"}
                />
            </NavLink>
        </>
    );
};

export default withRouter(AppRoutes);
