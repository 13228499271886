import React from "react";
import { map } from "lodash";
import { useHistory } from "react-router-dom";
import { PageWrapper } from "xa-generics";
import { IRouterProps } from "xa-navigation";
import { FoundationData } from "../Static/FoundationData.static";
import { useTranslation } from "react-i18next";
import { IFoundationData, ILink, ITableElement } from "../Interface/IFoundationData.interface";
import "./Less/Foundation.css";

export interface IFoundationViewProps extends IRouterProps<{ foundation_id: string }> {}

const FoundationView: React.FC<IFoundationViewProps> = (props) => {
    const foundation_id: string = props.computedMatch.params.foundation_id;
    const history = useHistory();
    const { t } = useTranslation();

    if (!FoundationData[foundation_id]) {
        alert("Ismeretlen kezelt-alap azonosító!");
        console.error("There is no foundation with this id: ", foundation_id);
        history.goBack();
        return null;
    }

    const dataSource: IFoundationData = FoundationData[foundation_id];

    return (
        <PageWrapper>
            <div className="foundation-page">
                <div className="foundation-page__title">
                    <span className="text">{t(dataSource.title)}</span>
                    <button className="back-button" onClick={() => history.goBack()}>
                        {t("goBack")}
                    </button>
                </div>
                {map(dataSource.topTexts, (textKey: string, key: string) => {
                    return (
                        <div
                            className="foundation-page__text-block"
                            key={`${key}-text-block-${foundation_id}`}
                        >
                            {t(textKey)}
                        </div>
                    );
                })}

                {map(dataSource.links, (linkElement: ILink, key: number) => {
                    return (
                        <div
                            className="foundation-page__link-block"
                            key={`${key}-link-block-${foundation_id}`}
                        >
                            <div className="link-head">{t(linkElement.linkHeader)}</div>
                            <div className="link">
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={t(linkElement.link)}
                                >
                                    {t(linkElement.link)}
                                </a>
                            </div>
                        </div>
                    );
                })}

                <div className="foundation-page__table">
                    {map(dataSource.tableData, (col: ITableElement, key: number) => {
                        return (
                            <div
                                className="foundation-page__table--row"
                                key={`${key}-table-row-${foundation_id}`}
                            >
                                <div className="foundation-page__table--col title-col">
                                    <span>{t(col.title)}</span>
                                </div>
                                <div className="foundation-page__table--col desc-col">
                                    {col.desc instanceof Array ? (
                                        col.desc.map((text: string, descIndex: number) => (
                                            <span
                                                className="list-desc"
                                                key={`${foundation_id}-${key}-table-col-${descIndex}`}
                                            >
                                                {t(text)}
                                            </span>
                                        ))
                                    ) : (
                                        <span>{t(col.desc)}</span>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </PageWrapper>
    );
};

export default FoundationView;
