import { INavElement, NavRoot } from "xa-navigation";

export class Level0Routes extends NavRoot {
    public static levelName: string = "NONE";
    route1: INavElement = {
        route: "/",
        text: "Kezdőlap",
        sort_order: 1
    };
    route2: INavElement = {
        route: "/cegadatok",
        text: "Cégadatok",
        sort_order: 2
    };
    route3: INavElement = {
        route: "/kezelt-alapok",
        text: "Kezelt alapok",
        sort_order: 3
    };
    route4: INavElement = {
        route: "/kozzetetel",
        text: "Közzététel",
        sort_order: 4
    };
    // route4: INavElement = {
    // 	route: "/projects",
    // 	text: "Menedzsment",
    // 	sort_order: 4
    // };
    // route5: INavElement = {
    // 	route: "/posts",
    // 	text: "Közzétételek",
    // 	sort_order: 5
    // };
    route6: INavElement = {
        route: "/kapcsolat",
        text: "Kapcsolat",
        sort_order: 6
    };
    route7: INavElement = {
        route: "/panaszkezeles",
        text: "Panaszkezelés",
        sort_order: 7
    };
    route8: INavElement = {
        route: "/impresszum",
        text: "Impresszum",
        sort_order: 7
    };
    route9: INavElement = {
        route: "/adatkezeles",
        text: "Adatkezelés",
        sort_order: 8
    };
}
